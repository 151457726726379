import { ConversionSchema } from "../transformers/conversion";
import { flavorAndRoastCarousel } from "../sanity/quick-shop-carousel-queries";
import { toNavProps } from "../transformers/nav";

type Experiment<T> = {
  id: string;
  [key: number]: T;
};

type Experiments = {
  makeItABundle: Experiment<boolean>;
  horizontalNavigation: string;
};

export const experiments: Experiments = {
  // Note: if this experiment wins move copies in hooks/useBundle.tsx to translation groups [!Important]
  // Note: 2 address error in the bundle component
  makeItABundle: {
    id: "42",
    1: false,
    2: true,
  },
  // GrowthBook

  // Note: if this experiment wins fix focus [!Important]
  // Ref: https://power-digital.slack.com/archives/C061HQMJRKR/p1743617727273739?thread_ts=1743617148.885819&cid=C061HQMJRKR
  horizontalNavigation: "BBC-Horizontal-Navigation",
};

// Flavor and roast module starts
// Note: This will remain here in experiments to keep track of this technical debt, where only the product bella-donovan will have the flavor and roast module and this will be distributed to all other products.
// Note: To remove this, global modules are required where data can be changed from a single point and affect all places where this module is present to maintain synchronization between them, but this feature is still under discussion.

export const getFlavorAndRoastModule = (
  lang: string,
) => `*[_type =="product" && !(_id in path("drafts.**")) 
  && slug.current == "bella-donovan"][0]
  .modules[_type == "flavorAndRoastCarousel"][0]{
  _type,
  _key,
  ${flavorAndRoastCarousel(lang)}
}`;

export const conversionWithFlavorAndRoastModule = ({
  flavorAndRoastModule,
  conversion,
}: {
  flavorAndRoastModule: ConversionSchema["modules"][0];
  conversion: ConversionSchema;
}) => ({
  ...conversion,
  modules: [
    ...conversion.modules.filter(
      (module) => module._type !== "flavorAndRoastCarousel",
    ),
    flavorAndRoastModule,
  ].filter(Boolean), // In case flavorAndRoastModule is null meaning no flavor and roast module is present
});
// Flavor and roast module ends

type ToNavPropsReturnType = ReturnType<typeof toNavProps>;

// Horizontal Navigation A/B test
export const horizontalNavigationABTest = (nav: ToNavPropsReturnType) => {
  const navItems = nav.navItems.filter((item) => item.name !== "SHOP");

  const shopNav = {
    ...nav.navItems[0],
    navItems: nav.navItems[0].navItems.filter(
      (item) => item.name !== "Subscriptions",
    ),
  };

  return {
    ...nav,
    navItems: [shopNav, ...navItems],
  };
};
